import Vue from 'vue';
import App from './App.vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router';
import http from './http';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import common from './common/function';
import 'swiper/swiper-bundle.css';
import store from './store';
import VueI18n from 'vue-i18n';

import 'video.js/dist/video-js.css';

Vue.prototype.$http = http;
Vue.prototype.common = common;
Vue.config.productionTip = false;

Vue.use(VueAwesomeSwiper);
Vue.use(Vant);
Vue.use(VueI18n);

// 设置默认语言为韩文，如果 localStorage 中没有设置语言则使用 'ko_hy'
const userLang = localStorage.getItem('lang') || 'ko_hy';
localStorage.setItem('lang', userLang);

const i18n = new VueI18n({
  globalInjection: true,
  locale: userLang, // 使用存储语言或者默认韩语
  messages: {
    'yn_yu': require('./assets/languages/yn_yu.json'),
    'zh_cn': require('./assets/languages/zh_cn.json'),
    'en_us': require('./assets/languages/en_us.json'),
    'es_spa': require('./assets/languages/es_spa.json'),
    'ms_my': require('./assets/languages/ms_my.json'),
    'idn_yu': require('./assets/languages/idn_yu.json'),
    'ko_hy': require('./assets/languages/ko_hy.json'),
  }
});

new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app');
